import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo"

const ContactPage = (props) => {
    let title = "Work With Pyxel Development 🖥 | Sacramento Web Design"
    let keywords = ["Sacramento Website Help", "Sacramento Website Questions"]
    let description = "Pyxel Development is happy to answer any questions you might have regarding ANY website services, including, web design, web development, web maintenance, SEO, eCommerce, and Digital Marketing."
    let location = props.location.pathname
    return (
        <div>
            <Layout>
                <Seo title={title} keywords={keywords} location={location} description={description} />
                <div className="bg-offblue">
                    <div className="container mx-auto">
                        <div className="grid-cols-2 lg:grid bg-offblue">
                            <div className="p-8 py-32 md:py-40 order-last lg:p-20 lg:py-60">
                                <div className="text-center max-w-lg mx-auto font-bold text-white text-sm px-8 lg:hidden py-6">
                                    <h1 className="-ml-77 text-lg">CONTACT US</h1>
                                    <p className="text-black  text-4xl ml-2">WE ARE HERE FOR YOU</p>
                                    <p className="py-4 pb-8 text-black font-light text-base">Contact us with any questions you might have about the services and solutions that we offer.  We guarantee same day replies! Find out why our customer service is rated #1 TODAY!</p>
                                    <span className="py-2 px-2 text-black font-bold text-md">Call Us:</span>
                                    <a href="tel:916-821-9044" className="bg-black hover:bg-white hover:text-black text-white text-center py-2 px-4 rounded" title="Call Pyxel Development" alt="Call Pyxel Development">(916) 821-9044</a>
                                    <p className="pt-7 pb-4 px-2 text-black font-bold text-md">
                                        <span className="px-2">Email:</span>
                                        <a href="mailto:admin@pyxeldev.com" className="bg-black hover:bg-white hover:text-black text-white text-center py-2 px-4 rounded text-sm" title="Email Pyxel Development" alt="Email Pyxel Development">admin@pyxeldev.com</a>
                                    </p>
                                </div>
                                <form name="Contact Page" action="/success" method="POST" data-netlify="true" className="w-full max-w-3xl px-14 py-5 lg:px-6 xl:px-14 bg-white lg:-mb-28 rounded-2xl mx-auto">
                                    <input type="hidden" name="form-name" value="Contact Page" />
                                    <h2 className="text-center text-3xl font-bold mt-2">Let's Get Started!</h2>
                                    <hr className="mb-6 border border-black " />
                                    <div className="flex flex-wrap -mx-2 mb-6">
                                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">First Name:
                                                <input className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" name="first name" required placeholder="Ex: Peter" />
                                            </label>
                                            <p className="text-xs italic">*Required</p>
                                        </div>
                                        <div className="w-full md:w-1/2 px-3">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">Last Name:
                                                <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" name="last name" placeholder="Ex: Ross" />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap -mx-2 mb-6">
                                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="company-name">Company Name:
                                                <input className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="company-name" type="text" name="company" placeholder="Ex: Pyxel Development" />
                                            </label>
                                            <p className="text-xs italic">*Optional</p>
                                        </div>
                                        <div className="w-full md:w-1/2 px-3">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="phone">Phone:
                                                <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="phone" name="phone" type="tel" placeholder="Ex: 123-456-7890" />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap -mx-3 mb-6">
                                        <div className="w-full px-3">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" required htmlFor="email">E-mail:
                                                <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="email" type="email" name="email" required placeholder="Ex: PeterRoss@mail.com" />
                                            </label>
                                            <p className="text-xs italic">*Required</p>
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap -mx-3 mb-6">
                                        <div className="w-full px-3">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="message">Question / Message:
                                                <textarea className=" no-resize appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none" id="message" name="message" required placeholder="i.e. Questions, Comments, Concerns, etc..."></textarea>
                                            </label>
                                            <p className="text-xs italic">*Required</p>
                                        </div>
                                    </div>
                                    <div className="flex justify-center -mt-2 mb-4 mx-auto">
                                        <button type="submit" title="Send Pyxel Development a Message" alt="Send Pyxel Development a Message" className="shadow bg-black border hover:bg-white hover:text-black hover:border-black focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded-xl w-48">Send Message</button>
                                    </div>
                                </form>
                            </div>
                            <div className="invisible lg:visible -mb-56 md:-mb-52 text-center max-w-lg mx-auto -mt-40 font-bold text-white text-sm px-8 lg:mt-80 lg:py-5 lg:text-left">
                                <h1 className="-ml-77 lg:text-lg">CONTACT US</h1>
                                <p className="text-black text-3xl ml-2 lg:ml-0 lg:text-5xl">WE ARE HERE FOR YOU</p>
                                <p className="py-4 text-black font-light text-base">Contact us with any questions you might have about the services and solutions that we offer.  We guarantee same day replies! Find out why our customer service is rated #1 TODAY!</p>
                                <div className="mt-12 ">
                                    <span className="py-2 px-2 text-black font-bold text-lg">Call Us:</span>
                                    <a href="tel:916-821-9044" title="Call Pyxel Development" alt="Call Pyxel Development" className="bg-black hover:bg-white hover:text-black text-white text-center py-2 px-4 rounded ">(916)821-9044</a>
                                    <p className="py-5 px-2 text-black font-bold text-lg">
                                        <span className="px-2">Email:</span>
                                        <a href="mailto:admin@pyxeldev.com" className="bg-black hover:bg-white hover:text-black text-white text-center py-2 px-4 rounded text-sm" title="Email Pyxel Development" alt="Email Pyxel Development">admin@pyxeldev.com</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    )
}
export default ContactPage
